/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from "react";
import HeaderWithBanner from "../../components/header/HeaderWithBanner";
import Footer from "../../components/footer/Footer";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.min.css";
import trackingService from "../../services/trackingService";
import paginationFactory from "react-bootstrap-table2-paginator";
import { CSVLink } from "react-csv";
import { imsLoader, arrowLeft, excelIcon } from "../../assets";
import ErrorBoundary from "../../components/ErrorBoundary/ErrorBoundary";
import Services from "../../utils/services";
import ApiServices from "../../utils/apiServices";
import NavigationMenu from "../../components/Navigation/NavigationMenu";
import "../../assets/css/reports.css";
import AlertMessagePopUpModal from "../../components/PopUp/AlertMessagePopUpModal"
import moment from 'moment';
let ts = new trackingService();

const customRowStyle = (row, rowIndex) => {
  const style = {};
  if (rowIndex % 2 === 1 || row.id === 0) {
    style.backgroundColor = "#E1F1F9";
  }
  return style;
};
var date = new Date();
// add a day
date.setDate(date.getDate() - 7);
class Reports extends Component {
  userid;
  userExists;
  resultSet;
  constructor(props) {
    super(props);
    this.csvLink = React.createRef();
    this.state = {
      isLoaded: false,
      userExists: false,
      //startDate: new Date(),
      startDate: date,
      endDate: new Date(),
      itemLimit: 500,
      resultSet: [],
      resultCount: 0,
      reportTitle: "",
      columns: [],
      noDataMsg: "Please click on a report link to see the report here!",
      isError: false,
      csvData: [],
      csvFilename: "",
      headers: [],
      keyField: "userinfo.wwid",
      activeUsers: "NA",
      reportClassName: "rptTable",
      reportTypeMPD: "mpd",
      reportTypeMAD: "mad",
      repDocType: "",
      width: window.innerWidth,
      height: window.innerHeight,
      alertMessageModelColor: "",
      alertMessageContent: "",
      alertMessageShowModal: false,
      userWWID: 0,
      username: '',
    };
    this.handleSDChange = this.handleSDChange.bind(this);
    this.handleEDChange = this.handleEDChange.bind(this);
    this.handleReportLinkClick = this.handleReportLinkClick.bind(this);
    this.generateCSV = this.generateCSV.bind(this);
    this.handleMPDClick = this.handleMPDClick.bind(this);
    this.handleDTClick = this.handleDTClick.bind(this);
    this.handleMADClick = this.handleMADClick.bind(this);
    this.handleMPDDetailsClick = this.handleMPDDetailsClick.bind(this);
    this.openDocType = this.openDocType.bind(this);
  }
  handleSDChange(date) {
    this.setState({
      startDate: date,
    });
  }
  handleEDChange(date) {
    this.setState({
      endDate: date,
    });
  }

  errorAlertMessage = (message) => {
    this.setState({
      alertMessageContent: message,
      alertMessageModelColor: "#de1327",
      alertMessageShowModal: true
    })
  }

  closeAlertMessage = () => {
    this.setState({
      alertMessageContent: "",
      alertMessageModelColor: "",
      alertMessageShowModal: false
    })
  }

  async generateCSV(rt, sd, ed) {
    //let ts = new trackingService();
    switch (rt) {
      case "wsw":
        this.setState({
          csvFilename: "iMS_Report_Who-Searched-What.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "iu":
        this.setState({
          csvFilename: "iMS_Report_InactiveUsers.csv",
          headers: [
            { label: "WWID", key: "wwid" },
            { label: "Name", key: "username" },
            { label: "Role", key: "profession" },
            { label: "Department", key: "department" },
            // { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "regions" },
            { label: "Email", key: "email" },
            { label: "Last Login Date", key: "lastlogindate" },
          ],
        });
        break;
      case "wdw":
        this.setState({
          csvFilename: "iMS_Report_Content-Interactions.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "User Products", key: "userinfo.userproducts" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Link", key: "documentinfo.docurl" },
            { label: "Ordered By", key: "documentinfo.orderedby" },
            { label: "Ordered For", key: "documentinfo.orderedfor" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
            { label: "Document Type", key: "documentinfo.doctype" },
            { label: "Content Source", key: "documentinfo.contentsource" },
            { label: "Source", key: "eventinfo.eventlabel" },
            { label: "Action", key: "eventinfo.eventaction" },
          ],
        });
        break;
      case "uep":
        this.setState({
          csvFilename: "iMS_Report_User-Entry-Point.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "User's Entry Point", key: "eventinfo.eventlabel" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "sed":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Download-View.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Ordered By", key: "documentinfo.orderedby" },
            { label: "Ordered For", key: "documentinfo.orderedfor" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
            { label: "Document Type", key: "documentinfo.doctype" },
            { label: "Content Source", key: "documentinfo.contentsource" },
            { label: "Source", key: "eventinfo.eventlabel" },
          ],
        });
        break;
      case "seshare":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Share.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
          ],
        });
        break;
      case "sepreview":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Preview.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
          ],
        });
        break;
      case "seemail":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Email.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
          ],
        });
        break;
      case "seel":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Email-List.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Email Link", key: "eventinfo.eventlabel" },
          ],
        });
        break;
      case "sepr":
        this.setState({
          csvFilename: "iMS_Report_Success-Pin-to-Reading-List.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Document Title", key: "documentinfo.title" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Version", key: "documentinfo.version" },
            { label: "Last Modified", key: "documentinfo.lastmodified" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page Number", key: "documentinfo.pagenumber" },
            { label: "Operating System", key: "userinfo.deviceos" },
            { label: "Products", key: "documentinfo.docproducts" },
            { label: "Document Number", key: "documentinfo.docnumber" },
            { label: "Document Type", key: "documentinfo.doctype" },
          ],
        });
        break;
      case "gnds":
        this.setState({
          csvFilename: "iMS_Report_Searches-With-No-Download.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "ssa":
        this.setState({
          csvFilename: "iMS_Report_Abandoned-Search-Sessions.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "mpd":
        this.setState({
          csvFilename: "iMS_Report_Most_Popular_Document.csv",
          headers: [
            { label: "Document Type", key: "DocType" },
            { label: "Content Source", key: "cs" },
            { label: "Count", key: "count" },
          ],
        });
        break;
      case "dt":
        this.setState({
          csvFilename: "iMS_Report_Document_Types.csv",
          headers: [
            { label: "Document Type", key: "DocType" },
            { label: "Count", key: "count" },
          ],
        });
        break;
      case "mad":
        this.setState({
          csvFilename: "iMS_Report_Most_Active_Department.csv",
          headers: [
            { label: "Department", key: "DeptName" },
            { label: "Count", key: "Deptcount" },
          ],
        });
        break;
      case "mpdd":
        this.setState({
          csvFilename: "iMS_Report_Most_Popular_Document_Details.csv",
          headers: [
            { label: "Document Title", key: "DocTitle" },
            { label: "Document Number", key: "DocNumber" },
            { label: "Count", key: "count" },
          ],
        });
        break;
      case "ses":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Save.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page URL", key: "eventinfo.eventsource" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "sealert":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Alert.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page URL", key: "eventinfo.eventsource" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "sebookmark":
        this.setState({
          csvFilename: "iMS_Report_Success-Event-Bookmark.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Date", key: "eventinfo.datehour" },
            { label: "Keyword", key: "searchinfo.keyword" },
            { label: "Filter", key: "searchinfo.filters" },
            { label: "Page URL", key: "eventinfo.eventsource" },
            { label: "Operating System", key: "userinfo.deviceos" },
          ],
        });
        break;
      case "auu":
        this.setState({
          csvFilename: "iMS_Report_Authoring-Usage.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "usertitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentdepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
            { label: "Last Summarized Date", key: "lastSummarizedDate" },
            { label: "No. of Summarized Content", key: "numberofSummarization" },
            { label: "No. of Summery Saved to Notebooks", key: "noofnotebooks" },
            { label: "No. of Notebooks", key: "numberofSaveNotebook" },
            { label: "Request Token", key: "requestTokenCost" },
            { label: "Response Token", key: "responseTokenCost" },
            { label: "Total Cost", key: "totalcost" },
          ],
        });
        break;
      case "anu":
        this.setState({
          csvFilename: "iMS_Report_Answering-Usage.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "usertitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentdepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
            { label: "Last Conducted Question Date", key: "lastConductedQuestionDate" },
            { label: "No. of Searches", key: "NoofSearches" },
            { label: "No. of Conducted Questions", key: "numberOfConductedQuestions" },
            { label: "No. of Preview Main Answers", key: "numberOfPreviewMainAnswering" },
            { label: "No. of Preview Recommendation Answers", key: "numberOfPreviewRecommendationAnswering" },
            { label: "No. of Download Main Answers", key: "numberOfDownloadMainAnswering" },
            { label: "No. of Download Recommendation Answers", key: "numberOfDownloadRecommendationAnswering" },
            { label: "Prompt Token", key: "promptTokens" },
            { label: "Completion Token", key: "completionTokens" },
            { label: "Total Cost", key: "totalcost" },
            { label: "No. of Likes", key: "likes" },
            { label: "No. of Dislikes", key: "dislikes" },
          ],
        });
        break;
      case "an2dwu":
        this.setState({
          csvFilename: "iMS_Report_Answering-2.0-Days-Wise-Usage.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "Date", key: "date" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "userTitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentDepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
            { label: "No. of Conducted Questions", key: "conductedQuestion" },
            { label: "No. of Preview Main Answers", key: "previewAnsweringDocument" },
            { label: "No. of Preview Recommendation Answers", key: "previewAnsweringRecommendationsDocument" },
            { label: "No. of Download Main Answers", key: "viewORDownloadAnsweringDocument" },
            { label: "No. of Download Recommendation Answers", key: "viewORDownloadAnsweringRecommendationsDocument" }
          ],
        });
        break;
      case "an2lu":
        this.setState({
          csvFilename: "iMS_Report_Answering-2.0-Like-Dislike.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "Reaction", key: "answer_reaction" },
            { label: "Question", key: "question" },
            { label: "Document Filters", key: "filter" },
            { label: "Sources", key: "sources" },
            { label: "Answers", key: "displayStrings" },
            { label: "Is Multi Files", key: "ismultifiles" },
            { label: "Thread id", key: "thread_id" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "userTitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentDepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
          ],
        });
        break;
      case "an2af":
        this.setState({
          csvFilename: "iMS_Report_Answering-2.0-Feedback.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "Feedback", key: "answer_feedback" },
            { label: "Question", key: "question" },
            { label: "Document Filters", key: "filter" },
            { label: "Sources", key: "sources" },
            { label: "Answers", key: "displayStrings" },
            { label: "Is Multi Files", key: "ismultifiles" },
            { label: "Thread id", key: "thread_id" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "userTitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentDepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
          ],
        });
        break;
        case "an2qna":
        this.setState({
          csvFilename: "iMS_Report_Answering-2.0-QnA.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "Question", key: "question" },
            { label: "Document Filters", key: "filter" },
            { label: "Sources", key: "sources" },
            { label: "Answers", key: "displayStrings" },
            { label: "Is Multi Files", key: "ismultifiles" },
            { label: "Thread id", key: "thread_id" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "userTitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentDepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
          ],
        });
        break;
      case "answer":
        this.setState({
          csvFilename: "iMS_Report_AnswersQ&A.csv",
          headers: [
            { label: "WWID", key: "userinfo.wwid" },
            { label: "Name", key: "userinfo.name" },
            { label: "Role", key: "userinfo.function" },
            { label: "Department", key: "userinfo.department" },
            { label: "Company Name", key: "userinfo.companyname" },
            { label: "Location", key: "userinfo.region" },
            { label: "Email", key: "userinfo.email" },
            { label: "Question", key: "assistantinfo.question" },
            { label: "Answer", key: "assistantinfo.answers" },
            { label: "Section Answer", key: "assistantinfo.sectionanswer" },
            { label: "Document Types", key: "assistantinfo.filters" },
            { label: "Doc Title", key: "assistantinfo.doctitle" },
            { label: "Document Number", key: "assistantinfo.documentnumber" },
            { label: "Download URL", key: "assistantinfo.downloadurl" },
            { label: "Search ID", key: "assistantinfo.searchid" },
            { label: "Session ID", key: "assistantinfo.sessionid" },
          ],
        });
        break;
      case "an2u":
        this.setState({
          csvFilename: "iMS_Report_Answering2.0-Usage.csv",
          headers: [
            { label: "Sr. No", key: "SrNo" },
            { label: "WWID", key: "wwid" },
            { label: "User Title", key: "usertitle" },
            { label: "Email", key: "email" },
            { label: "Current Department", key: "currentdepartment" },
            { label: "Region", key: "region" },
            { label: "Countries", key: "countries" },
            { label: "Last Conducted Question Date", key: "lastConductedQuestionDate" },
            { label: "No. of Searches", key: "NoofSearches" },
            { label: "No. of Conducted Questions", key: "numberOfConductedQuestions" },
            { label: "No. of Preview Main Answers", key: "numberOfPreviewMainAnswering" },
            { label: "No. of Preview Recommendation Answers", key: "numberOfPreviewRecommendationAnswering" },
            { label: "No. of Download Main Answers", key: "numberOfDownloadMainAnswering" },
            { label: "No. of Download Recommendation Answers", key: "numberOfDownloadRecommendationAnswering" },
            { label: "No. of Likes", key: "likes" },
            { label: "No. of Dislikes", key: "dislikes" },
          ],
        });
        break;
      default:
        this.setState({
          reportTitle: "No - Title",
        });
        break;
    }
    let response;
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      if (ed.getTime() >= sd.getTime()) {
        if (rt === "mpd") {
          response = await ts.getMostPopularDocument(sd, ed);
        } else if (rt === "dt") {
          response = await ts.getDocumentTypes();
        } else if (rt === "mad") {
          response = await ts.getMostActiveDepartment(sd, ed);
        } else if (rt === "mpdd") {
          response = await ts.getMostPopularDocumentDetails(
            sd,
            ed,
            this.state.repDocType
          );
        } else if (rt === "auu") {
          response = await ts.getAuthoringUsage(
            sd,
            ed
          );
        }
        else if (rt === "anu") {
          response = await ts.getAnsweringUsage(
            sd,
            ed
          );
        }
        else if (rt === "an2u") {
          response = await ts.getAnswering2Usage(
            sd,
            ed
          );
        }
        else if (rt === "an2dwu") {
          response = await ts.getAnswering2DaysWiseUsage(
            sd,
            ed
          );
        }
        else if (rt === "an2lu") {
          response = await ts.getAnswering2LikeUnlike(
            sd,
            ed
          );
        }
        else if (rt === "an2af") {
          response = await ts.getAnswering2AnswerFeedback(
            sd,
            ed
          );
        }
        else if (rt === "an2qna") {
          response = await ts.getAnswering2AnswerQnA(
            sd,
            ed
          );
        }
        else {
          response = await ts.getTrackingData(rt, sd, ed);
        }
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
            this.errorAlertMessage("There is no data for the selected period and report.");
            return false;
          } else {
            let cData = [];
            if (rt === "iu") {
              for (let i = 0; i < response.data.rows.length; i++) {
                cData.push(response.data.rows[i]);
              }
            }
            else if (rt === "auu" || rt === "anu" || rt === "an2u") {
              for (let i = 0; i < response.data.length; i++) {
                cData.push({ ...response.data[i], lastConductedQuestionDate: response.data[i].lastConductedQuestionDate ? moment(response.data[i].lastConductedQuestionDate).format("D-MMM-YYYY") : "", SrNo: i + 1 });
              }
            }
            else if (rt === "an2dwu") {
              for (let i = 0; i < response.data.length; i++) {
                cData.push({ ...response.data[i], date: response.data[i].date ? moment(response.data[i].date).format("D-MMM-YYYY") : "", SrNo: i + 1 });
              }
            }
            else if (rt === "an2lu" || rt === "an2af"|| rt === "an2qna") {
              for (let i = 0; i < response.data.length; i++) {
                cData.push({ ...response.data[i], SrNo: i + 1 });
              }
            }
            else if (rt === "mpd" || rt === "mpdd" || rt === "mad" || rt === "dt") {
              for (let i = 0; i < response.data.length; i++) {
                cData.push(response.data[i]);
              }
            } else {
              for (let i = 0; i < response.data.length; i++) {
                cData.push(response.data[i]._source);
              }
            }
            this.setState({ csvData: cData }, () => {
              setTimeout(() => {
                this.csvLink.current.link.click();
              });
            });
          }
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
    }
  }
  openDocType(val) {
    this.handleMPDDetailsClick(this.state.startDate, this.state.endDate, val);
  }
  async handleMPDDetailsClick(sd, ed, dt) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Most Popular Document Details - " + dt,
      keyField: "rowid",
      reportClassName: "rptTableMPD",
      reportTypeMPD: "mpdd",
      repDocType: dt,
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "DocTitle",
          text: "Document Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "DocNumber",
          text: "Document Number",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "count",
          text: "Download Count",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getMostPopularDocumentDetails(sd, ed, dt);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleMPDClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Most Popular Document",
      keyField: "rowid",
      reportClassName: "rptTableMPD",
      reportTypeMPD: "mpd",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "DocType",
          text: "Document Type",
          sort: true,
          style: {
            cursor: "pointer",
            "text-decoration": "underline",
            color: "#1076bc",
          },
          formatter: (cell, row, rowIndex, extraData) => (
            <div>
              <a onClick={() => this.openDocType(cell)}> {cell} </a>
            </div>
          ),
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "cs",
          text: "Content Source",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "count",
          text: "Count",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getMostPopularDocument(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleDTClick() {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Document Types",
      keyField: "rowid",
      reportClassName: "rptTableMPD",
      reportTypeMPD: "dt",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "DocType",
          text: "Document Type",
          sort: true,
          // style: {
          //   cursor: "pointer",
          //   "text-decoration": "underline",
          //   color: "#1076bc",
          // },
          // formatter: (cell, row, rowIndex, extraData) => (
          //   <div>
          //     <a onClick={() => this.openDocType(cell)}> {cell} </a>
          //   </div>
          // ),
        },

        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "count",
          text: "Count",
          sort: true,
        },
      ],
    });
    let response = await ts.getDocumentTypes();
    if (response) {
      if (response.data.length === 0) {
        this.setState({
          resultSet: [],
          resultCount: 0,
          noDataMsg: "There is no data for the selected period and report.",
        });
      } else {
        this.setState({
          resultSet: response.data,
          resultCount: response.data.length,
        });
      }
    } else {
      this.setState({
        resultSet: [],
        resultCount: 0,
        noDataMsg: "There is no data for the selected period and report.",
      });
    }
    this.setState({
      isLoaded: true,
    });

  }
  async handleMADClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Most Active Department",
      keyField: "rowid",
      reportClassName: "rptTableMPD",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "DeptName",
          text: "Department",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "Deptcount",
          text: "Count",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getMostActiveDepartment(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleReportLinkClick(rt, sd, ed) {
    this.setState({
      isLoaded: false,
      reportClassName: "rptTable",
    });
    switch (rt) {
      case "wsw":
        this.setState({
          reportTitle: "Report - Who searched what",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "wdw":
        this.setState({
          reportTitle: "Report - Content Interaction",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.userproducts",
              text: "User Products",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.orderedby",
              text: "Ordered By",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.orderedfor",
              text: "Ordered For",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docurl",
              text: "Link",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
              sort: false,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.doctype",
              text: "Document Type",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.contentsource",
              text: "Content Source",
              sort: true
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventlabel",
              text: "Source",
              sort: true
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventaction",
              text: "Action",
              sort: true
            }
          ],
        });
        break;
      case "uep":
        this.setState({
          reportTitle: "Report - Users entry point",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventlabel",
              text: "User's Entry Point",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "sed":
        this.setState({
          reportTitle: "Success Event - Download/View",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.orderedby",
              text: "Ordered By",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.orderedfor",
              text: "Ordered For",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.doctype",
              text: "Document Type",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.contentsource",
              text: "Content Source",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventlabel",
              text: "Source",
            },
          ],
        });
        break;
      case "seshare":
        this.setState({
          reportTitle: "Success Event - Share Document",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
            },
          ],
        });
        break;
      case "sepreview":
        this.setState({
          reportTitle: "Success Event - Preview",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
            },
          ],
        });
        break;
      case "seemail":
        this.setState({
          reportTitle: "Success Event - Email",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
            },
          ],
        });
        break;
      case "seel":
        this.setState({
          reportTitle: "Success Event - Email List",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },

            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },

            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventlabel",
              text: "Email Link",
            },
          ],
        });
        break;
      case "sepr":
        this.setState({
          reportTitle: "Success Event - Pin to Reading List",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.title",
              text: "Document Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.version",
              text: "Version",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.lastmodified",
              text: "Last Modified",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.pagenumber",
              text: "Page Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docproducts",
              text: "Products",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.docnumber",
              text: "Document Number",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.documentinfo.doctype",
              text: "Document Type",
            },
          ],
        });
        break;
      case "gnds":
        this.setState({
          reportTitle: "Report - Searches with no download",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "iu":
        this.setState({
          reportTitle: "Report - Inactive Users",
          keyField: "wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "username",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "profession",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "regions",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "lastlogindate",
              text: "Last Login Date",
              sort: true,
            },
          ],
        });
        break;
      case "ssa":
        this.setState({
          reportTitle: "Report - Abandoned search sessions",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "ses":
        this.setState({
          reportTitle: "Success Event - Save",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventsource",
              text: "Page URL",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "sealert":
        this.setState({
          reportTitle: "Success Event - Alert",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventsource",
              text: "Page URL",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "sebookmark":
        this.setState({
          reportTitle: "Success Event - Bookmark",
          keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.datehour",
              text: "Date",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.keyword",
              text: "Keyword",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.searchinfo.filters",
              text: "Filters",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.eventinfo.eventsource",
              text: "Page URL",
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.deviceos",
              text: "Operating System",
              sort: true,
            },
          ],
        });
        break;
      case "answer":
        this.setState({
          reportTitle: "Answers Q & A",
          //  keyField: "userinfo.wwid",
          columns: [
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.wwid",
              text: "WWID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.name",
              text: "Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.function",
              text: "Role",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.department",
              text: "Department",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.companyname",
              text: "Company Name",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.region",
              text: "Location",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.userinfo.email",
              text: "Email",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.question",
              text: "Question",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.answers",
              text: "Answers",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.sectionanswer",
              text: "Section Answer",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.filters",
              text: "Document Types",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.doctitle",
              text: "Doc Title",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.documentnumber",
              text: "Document Number",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.downloadurl",
              text: "Download URL",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.searchid",
              text: "Search ID",
              sort: true,
            },
            {
              headerStyle: { backgroundColor: "#E1F1F9" },
              dataField: "_source.assistantinfo.sessionid",
              text: "Session ID",
              sort: true,
            },
          ],
        });
        break;

      default:
        this.setState({
          reportTitle: "No - Title",
        });
        break;
    }
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getTrackingData(rt, sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            if (rt === "iu") {
              //console.log(response.data.rows.length);
              this.setState({
                resultSet: response.data.rows,
                resultCount: response.data.rows.length,
              });
            } else {
              this.setState({
                resultSet: response.data,
                resultCount: response.data.length,
              });
            }
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
        this.setState({
          isLoaded: true,
        });
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAUUClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Authoring Usage",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "usertitle",
          text: "User Title",
          sort: true,
        },

        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentdepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "lastSummarizedDate",
          text: "Last Summarized Date",
          sort: true,
          formatter: (value) => {
            return value ? moment(value).format("D-MMM-YYYY") : ""
          },
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberofSummarization",
          text: "No. of Summarized Content",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberofSaveNotebook",
          text: "No. of Summery Saved to Notebooks",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "noofnotebooks",
          text: "No. of Notebooks",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "requestTokenCost",
          text: "Request Token",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "responseTokenCost",
          text: "Response Token",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "totalcost",
          text: "Total Cost",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAuthoringUsage(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleANUClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering Usage",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "usertitle",
          text: "User Title",
          sort: true,
        },

        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentdepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "lastConductedQuestionDate",
          text: "Last Conducted Question Date",
          sort: true,
          formatter: (value) => {
            return value ? moment(value).format("D-MMM-YYYY") : ""
          },
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "NoofSearches",
          text: "No. of Searches",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfConductedQuestions",
          text: "No. of Conducted Questions",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfPreviewMainAnswering",
          text: "No. of Preview Main Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "numberOfPreviewRecommendationAnswering",
          text: "No. of Preview Recommendation Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfDownloadMainAnswering",
          text: "No. of Download Main Answers",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "numberOfDownloadRecommendationAnswering",
          text: "No. of Download Recommendation Answers",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "promptTokens",
          text: "Prompt Token",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "completionTokens",
          text: "Completion Token",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "totalcost",
          text: "Total Cost",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "likes",
          text: "No. of Likes",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "dislikes",
          text: "No. of Dislikes",
          sort: true,
        }
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnsweringUsage(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAN2UClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering 2.0 Usage",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "usertitle",
          text: "User Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentdepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "lastConductedQuestionDate",
          text: "Last Conducted Question Date",
          sort: true,
          formatter: (value) => {
            return value ? moment(value).format("D-MMM-YYYY") : ""
          },
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "NoofSearches",
          text: "No. of Searches",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfConductedQuestions",
          text: "No. of Conducted Questions",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfPreviewMainAnswering",
          text: "No. of Preview Main Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "numberOfPreviewRecommendationAnswering",
          text: "No. of Preview Recommendation Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "numberOfDownloadMainAnswering",
          text: "No. of Download Main Answers",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "numberOfDownloadRecommendationAnswering",
          text: "No. of Download Recommendation Answers",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "likes",
          text: "No. of Likes",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "dislikes",
          text: "No. of Dislikes",
          sort: true,
        }
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnswering2Usage(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAN2DWUClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering 2.0 Days Wise Usage",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "date",
          text: "Date",
          sort: true,
          formatter: (value) => {
            return value ? moment(value).format("D-MMM-YYYY") : ""
          },
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "userTitle",
          text: "User Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentDepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "conductedQuestion",
          text: "No. of Conducted Questions",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "previewAnsweringDocument",
          text: "No. of Preview Main Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "previewAnsweringRecommendationsDocument",
          text: "No. of Preview Recommendation Answers",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "viewORDownloadAnsweringDocument",
          text: "No. of Download Main Answers",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "viewORDownloadAnsweringRecommendationsDocument",
          text: "No. of Download Recommendation Answers",
          sort: true,
        }
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnswering2DaysWiseUsage(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAN2LUClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering 2.0 Like Dislike",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "answer_reaction",
          text: "Reaction",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "250px" },
          style: { textAlign: "left" },
          dataField: "question",
          text: "Question",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "200px" },
          style: { textAlign: "left" },
          dataField: "filter",
          text: "Document Filters",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "sources",
          text: "Sources",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "displayStrings",
          text: "Answers",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "ismultifiles",
          text: "Is Multi Files",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "thread_id",
          text: "Thread id",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "userTitle",
          text: "User Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentDepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnswering2LikeUnlike(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAN2AFClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering 2.0 Feedback",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "answer_feedback",
          text: "Feedback",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "250px" },
          style: { textAlign: "left" },
          dataField: "question",
          text: "Question",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "200px" },
          style: { textAlign: "left" },
          dataField: "filter",
          text: "Document Filters",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "sources",
          text: "Sources",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "displayStrings",
          text: "Answers",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "ismultifiles",
          text: "Is Multi Files",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "thread_id",
          text: "Thread id",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "userTitle",
          text: "User Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentDepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnswering2AnswerFeedback(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async handleAN2QnAClick(sd, ed) {
    this.setState({
      isLoaded: false,
      reportTitle: "Report - Answering 2.0  Q & A",
      keyField: "wwid",
      reportClassName: "rptTable",
      repDocType: "",
      columns: [
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "250px" },
          style: { textAlign: "left" },
          dataField: "question",
          text: "Question",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "200px" },
          style: { textAlign: "left" },
          dataField: "filter",
          text: "Document Filters",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "sources",
          text: "Sources",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "300px" },
          style: { textAlign: "left" },
          dataField: "displayStrings",
          text: "Answers",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "ismultifiles",
          text: "Is Multi Files",
          sort: true,
        }
        ,
        {
          headerStyle: { backgroundColor: "#E1F1F9", width: "150px" },
          dataField: "thread_id",
          text: "Thread id",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "wwid",
          text: "WWID",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "userTitle",
          text: "User Title",
          sort: true,
        },
        {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "email",
          text: "Email",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "currentDepartment",
          text: "Current Department",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "region",
          text: "Region",
          sort: true,
        }, {
          headerStyle: { backgroundColor: "#E1F1F9" },
          dataField: "countries",
          text: "Countries",
          sort: true,
        },
      ],
    });
    if (sd == null) {
      this.errorAlertMessage("Please provide the start date!");
    } else if (ed == null) {
      this.errorAlertMessage("Please provide the end date!");
    } else {
      sd.getTime();
      ed.getTime();
      sd.setMilliseconds(0);
      ed.setMilliseconds(0);
      if (ed.getTime() >= sd.getTime()) {
        let response = await ts.getAnswering2AnswerQnA(sd, ed);
        if (response) {
          if (response.data.length === 0) {
            this.setState({
              resultSet: [],
              resultCount: 0,
              noDataMsg: "There is no data for the selected period and report.",
            });
          } else {
            this.setState({
              resultSet: response.data,
              resultCount: response.data.length,
            });
          }
        } else {
          this.setState({
            resultSet: [],
            resultCount: 0,
            noDataMsg: "There is no data for the selected period and report.",
          });
        }
      } else {
        this.setState({
          resultSet: [],
          resultCount: 0,
          isError: true,
          noDataMsg: "End date should not be less than start date",
        });
      }
      this.setState({
        isLoaded: true,
      });
    }
  }
  async componentDidMount() {
    document.title = "iMS - Reports";
    this.userid = JSON.parse(localStorage.getItem('username'));
    this.userExists = localStorage.getItem("userExists");
    let aUserResponse = await ts.getActiveUser();
    if (aUserResponse) {
      //if (aUserResponse.data.length > 0) {
      this.setState({

        activeUsers: aUserResponse,
      });
      //}
    }
    let service = new Services();
    let userid = JSON.parse(localStorage.getItem("username"));
    service.logService(
      `Reports page successfully mounted for ${userid.toLowerCase()}`,
      "info"
    );
    var adminRes;
    if (this.userExists) {
      this.setState({
        userExists: true,
        username: this.userid.toLowerCase(),
      }, async () => {
        const apiServices = new ApiServices();
        /*Call to DB to check if logged in User is an admin*/
        adminRes = await apiServices.getAdminDetails(this.state.username);
        if (adminRes[0].phm_ims_isadminfunc) {
          this.setState({
            userWWID: adminRes[0].phm_ims_isadminfunc,
            isLoaded: true
          })
        }
        else {
          this.setState({
            isLoaded: true
          })
        }
      });
    }
    else {
      console.log('Access Denied');
      this.props.history.push('/');
    }
    window.addEventListener("resize", this.updateDimensions);
  }
  componentWillMount() {
    let service = new Services();
    window.removeEventListener("resize", this.updateDimensions);
    let userid = JSON.parse(localStorage.getItem("username"));
    service.logService(
      `Reports page successfully unmounted for ${userid.toLowerCase()}`,
      "info"
    );
  }
  customTotal = (from, to, size) => (
    <span className="react-bootstrap-table-pagination-total">
      Showing {from} to {to} of {size} Results
    </span>
  );
  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };
  render() {
    const { alertMessageModelColor, alertMessageContent, alertMessageShowModal } = this.state;
    const options = {
      paginationSize: 9,
      pageStartIndex: 1,
      firstPageText: "First",
      prePageText: "Back",
      nextPageText: "Next",
      lastPageText: "Last",
      nextPageTitle: "First page",
      prePageTitle: "Pre page",
      firstPageTitle: "Next page",
      lastPageTitle: "Last page",
      showTotal: true,
      paginationTotalRenderer: this.customTotal,
      disablePageTitle: true,
      totalSize: this.state.resultCount,
      sizePerPageList: [
        {
          text: "10",
          value: 10,
        },
        {
          text: "25",
          value: 25,
        },
        {
          text: "50",
          value: 50,
        },
        {
          text: "100",
          value: 100,
        },
        {
          text: "All",
          value: this.state.resultCount,
        },
      ], // A numeric array is also available. the purpose of above example is custom the text
    };
    return (
      <div>
        <ErrorBoundary>
          {this.state.userExists ? (
            <>
              <>
                <NavigationMenu
                  pathName={window.location.pathname}
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
                <div>
                  <HeaderWithBanner />
                </div>
                {this.state.userWWID === 0 && this.state.isLoaded ?
                  <div className="admin-page-authorization-wrapper">
                    <p style={{ margin: "5%" }}>You are not authorized to view this page</p>
                  </div>
                  :
                  <div className="admin-dashboard-content-wrapper">
                    <div className="admin-data-table-wrapper">
                      <div>
                        <div className="reportTitle">
                          <div className="rptTitleLeft">
                            iMS Reports - Using Elastic
                          </div>
                          <div className="activeusers">
                            <span className="actvUsrText">Active Users:</span>
                            <span className="actvUsrVal">
                              {this.state.activeUsers}
                            </span>
                          </div>
                        </div>
                        <br />
                        <br />
                        <div className="headerWrapper">
                          <div className="rptCriHeader">Report Criteria</div>
                          <div className="genRptHeader">Generate Report</div>
                        </div>
                        <br />
                        <div className="dateWrapper">
                          <div className="dtPanelLeft">
                            <div className="datePanel">
                              <div className="divSD">
                                <div className="datePanelLabel">
                                  <b>Start Date:</b>
                                </div>
                                &nbsp;
                                <div className="datePanelControl">
                                  <DatePicker
                                    selected={this.state.startDate}
                                    onChange={this.handleSDChange}
                                    name="startDate"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </div>
                              </div>
                              <br />
                              <div className="divED">
                                <div className="enddatePanelLabel">
                                  <b>End Date:</b>
                                </div>
                                &nbsp;
                                <div className="enddatePanelControl">
                                  <DatePicker
                                    selected={this.state.endDate}
                                    onChange={this.handleEDChange}
                                    name="endDate"
                                    dateFormat="yyyy-MM-dd"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {/* <div className="reportPanel"> */}
                          <div className="rptPanelLeft">
                            <div className="reportLinkWSW">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "wsw",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Who searched what
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "wsw",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                              <CSVLink
                                headers={this.state.headers}
                                data={this.state.csvData}
                                target="_blank"
                                ref={this.csvLink}
                                filename={this.state.csvFilename}
                              ></CSVLink>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "uep",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - User's entry point
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "uep",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "wdw",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Content Interaction
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "wdw",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "gnds",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Searches with no download
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "gnds",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleMADClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Most Active Department
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "mad",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "seel",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Email List
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "seel",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "seshare",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Share Document
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "seshare",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "sepreview",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Preview
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "sepreview",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "seemail",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Email
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "seemail",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAUUClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Authoring Usage
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "auu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "answer",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Answers Q & A
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "answer",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAN2DWUClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering 2.0 Days Wise Usage
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "an2dwu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAN2LUClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering 2.0 Like Dislike
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "an2lu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAN2QnAClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering 2.0  Q & A
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "an2qna",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                          </div>
                          <div className="rptPanelRight">
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "ssa",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Abandoned search sessions
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "ssa",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "iu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Inactive Users (End Date is last login
                                date)
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "iu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "sed",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Download/View
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "sed",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleMPDClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Report - Most Popular Document
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    this.state.reportTypeMPD,
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "sepr",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Pin to Reading List
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "sepr",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "ses",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Save
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "ses",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "sealert",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Alert
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "sealert",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleReportLinkClick(
                                    "sebookmark",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Success Event - Bookmark
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "sebookmark",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleDTClick()
                                }
                              >
                                Report - Document Types
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "dt",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleANUClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering Usage
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "anu",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAN2UClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering 2.0 Usage
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "an2u",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                            <div className="reportLinks">
                              <img src={arrowLeft} alt="Left Arrow" />
                              &nbsp;
                              <a
                                className="reportLink"
                                onClick={() =>
                                  this.handleAN2AFClick(
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              >
                                Answering 2.0 Feedback
                              </a>
                              &nbsp;
                              <img
                                src={excelIcon}
                                alt="Excel Icon"
                                onClick={() =>
                                  this.generateCSV(
                                    "an2af",
                                    this.state.startDate,
                                    this.state.endDate
                                  )
                                }
                              ></img>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                        {this.state.resultCount > 0 ? (
                          <div className="rptDataPanel">
                            {this.state.isLoaded ? (
                              <div>
                                <div className="reportTitle">
                                  {this.state.reportTitle}
                                </div>
                                <br />
                                <div className={this.state.reportClassName}>
                                  <BootstrapTable
                                    keyField={this.state.keyField}
                                    data={this.state.resultSet}
                                    columns={this.state.columns}
                                    pagination={paginationFactory(options)}
                                    wrapperClasses="table-responsive"
                                    rowStyle={customRowStyle}
                                  ></BootstrapTable>
                                </div>
                              </div>
                            ) : (
                              <div>
                                <div
                                  style={{ textAlign: "center", padding: "10%" }}
                                >
                                  <img
                                    src={imsLoader}
                                    alt="Loading Data"
                                    style={{ width: "10%" }}
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        ) : (
                          <div className="reportTitle">
                            {this.state.isError ? (
                              <div className="reportError">
                                {this.state.noDataMsg}
                              </div>
                            ) : (
                              <div>{this.state.noDataMsg}</div>
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                }
              </>
              <div style={{ margin: "0 1%" }}>
                <Footer
                  customWidth={this.state.width}
                  customHeight={this.state.height}
                />
              </div>
            </>
          ) : (
            <div>Please login</div>
          )}
        </ErrorBoundary>
        {alertMessageShowModal && <AlertMessagePopUpModal color={alertMessageModelColor} content={alertMessageContent} showModal={alertMessageShowModal} handleClose={() => this.closeAlertMessage()} />}
      </div>
    );
  }
}
export default Reports;
