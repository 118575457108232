/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/alt-text */
import React, { Component } from "react";
import {
  IMSLogo,
  dropdownArrowBlack,
  crossIcon,
  iIcon,
  fieldedSrchIcon,
  magnifyingGlass,
  GenAIAnswers
} from "../../assets";
import "./HeaderSearch.css";
import { Link } from "react-router-dom";
import trackingService from "../../services/trackingService";
import GenerateArrayStrings from "../GenerateArrayStrings";
import SuggestionsList from "../AutoComplete/SuggestionsList";
import OpenWindow from "../OpenWindow";
import Button from "../Button";
import GlobalFeatures from "../GlobalSearchFeatures/GlobalFeatures";
import ApiServices from "../../utils/apiServices";

class HeaderSearch extends Component {
  isRussian;
  constructor(props) {
    super(props);
    /* this.inputBoxRef = React.createRef(); */
    this.state = {
      openPopUp: false,
      showNav: false,
      infoPopUp: false,
      dropdownValue: "All Fields",
      searchKeyword: "",
      appliedFilters: [], //Filters that are applied for while search is happening
      showOptions: false,
      showSuggestions: false,
      keyBoardEvent: false,
      keyEventName: "",
      fieldedValue: "",
      isClickOnSuggestion: false,
      bannerMessage: null
    };
  }
  async componentDidMount() {
    //console.log(this.props.dropdownValue);
    this.isRussian = JSON.parse(localStorage.getItem("russianuser"));
    if (this.props.dropdownValue !== "" && this.props.searchKeyword !== "") {
      this.setState({
        dropdownValue: this.props.dropdownValue,
        searchKeyword: this.props.searchKeyword,
        appliedFilters: this.props.appliedFilters,
      });
    }
    await this.getBannerMessage();
    //window.addEventListener('blur',this.handleCloseSuggestions)
  }
  componentWillUnmount() {
    //window.removeEventListener('blur',this.handleCloseSuggestions)
  }
  componentWillReceiveProps(newProps) {
    if (this.props.isClearSearch) {
      this.setState({
        searchKeyword: this.props.searchKeyword,
        dropdownValue: this.props.dropdownValue,
      });
    }
    if (
      newProps.searchKeyword !== this.props.searchKeyword ||
      newProps.dropdownValue !== this.props.dropdownValue
    ) {
      this.setState({
        searchKeyword: newProps.searchKeyword,
        dropdownValue: newProps.dropdownValue,
      });
    }
  }

  getBannerMessage = async () => {
    let apiService = new ApiServices();
    let banner = await apiService.getBannerMessage();
    if (banner && banner.length > 0) {
      let firstBannerMessage = banner[0];
      if (firstBannerMessage.is_visible) {
        this.setState({ bannerMessage: firstBannerMessage.message })
      }
    }

  };
  handleOnSuggestionClick = (isClick) => {
    this.setState({
      isClickOnSuggestion: isClick,
    });
  };
  handleOnHover = () => {
    this.setState({
      openPopUp: true,
    });
  };
  handleOnHoverLeave = () => {
    this.setState({
      openPopUp: false,
    });
  };
  handleOnInfoHover = () => {
    this.setState({
      infoPopUp: true,
    });
  };
  handleOnInfoHoverLeave = () => {
    this.setState({
      infoPopUp: false,
    });
  };
  handleNav = () => {
    this.setState({
      showNav: !this.state.showNav,
    });
  };
  handleClose = () => {
    this.setState({
      showNav: false,
    });
  };
  handleCategoryDropdown = () => {
    this.setState({
      showOptions: !this.state.showOptions,
    });
  };
  handleOpenContentTitle = () => {
    let url = window.location.origin + "/covid";
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Expanded Covid-19 Content by Product tile",
      "",
      window.location.href
      //   this.state.searchKeyword,
      //   this.props.appliedFilters
    );
    OpenWindow(url);
  };
  handleChangeInDropdown = (event) => {
    //console.log(event.target.attributes.keyValue.value);
    let fieldedVal;
    event.preventDefault();
    if (event.target.attributes.keyvalue.value === "All Fields") {
      fieldedVal = "";
    } else if (event.target.attributes.keyvalue.value === "Author") {
      fieldedVal = "authors";
    } else if (event.target.attributes.keyvalue.value === "Congress") {
      fieldedVal = "fullCnSf";
    } else if (event.target.attributes.keyvalue.value === "Document Type") {
      fieldedVal = "docTypeSynonyms";
    } else if (event.target.attributes.keyvalue.value === "Title") {
      fieldedVal = "docTitle";
    } else if (event.target.attributes.keyvalue.value === "Publication Year") {
      fieldedVal = "strYear";
    } else if (event.target.attributes.keyvalue.value === "Journal Name") {
      fieldedVal = "journalName";
    }
    //const newUrl = this.buildUrl(this.state.searchKeyword,fieldedVal,this.props.appliedFilters)
    this.setState({
      dropdownValue: event.target.attributes.keyvalue.value,
      showOptions: !this.state.showOptions,
      fieldedValue: fieldedVal,
    });
  };
  handleChangeInSearch = (event) => {
    /* let filters = this.props.location.search
        console.log(filters) */
    this.setState({
      searchKeyword: event.target.value,
      showSuggestions: true,
    });
  };
  handleCancelSearchKeyword = () => {
    this.setState({
      searchKeyword: "",
      showSuggestions: false,
    });
  };
  buildUrl = (searchKeyword, dropdownValue, fieldedVal, filters) => {
    if (!fieldedVal) {
      if (dropdownValue === "All Fields") {
        fieldedVal = "";
      } else if (dropdownValue === "Author") {
        fieldedVal = "authors";
      } else if (dropdownValue === "Congress") {
        fieldedVal = "fullCnSf";
      } else if (dropdownValue === "Document Type") {
        fieldedVal = "docTypeSynonyms";
      } else if (dropdownValue === "Title") {
        fieldedVal = "docTitle";
      } else if (dropdownValue === "Publication Year") {
        fieldedVal = "strYear";
      } else if (dropdownValue === "Journal Name") {
        fieldedVal = "journalName";
      }
    }
    searchKeyword = encodeURIComponent(searchKeyword);
    if (this.props.history.location.pathname === "/viewReadingList") {
      let paramsForviewResult = new URLSearchParams(
        this.props.history.location.search
      );
      let q = paramsForviewResult.get("q");
      let filters = paramsForviewResult.get("filters");
      let f1 = paramsForviewResult.get("f1");
      let fval1 = paramsForviewResult.get("fval1");
      let fcount = paramsForviewResult.get("fcount");
      let rName = paramsForviewResult.get("rName");
      let rID = paramsForviewResult.get("rID");
      return `${this.props.history.location.pathname}?query=${searchKeyword}&q=${q}&filters=${filters}&f1=${f1}&fval1=${fval1}&fcount=${fcount}&rName=${rName}&rID=${rID}`;
    } else {
      const { isClickOnSuggestion } = this.state;
      let params = new URLSearchParams(this.props.history.location.search);
      let csPath = params.get("cspath");
      let csproduct = params.get("csproduct");
      let andFields = params.get("andFields");
      let orFields = params.get("orFields");
      let docTitle = params.get("docTitle");
      let authors = params.get("authors");
      let majorTopicsSynonyms = params.get("majorTopicsSynonyms");
      let FS = params.get("FS");
      let fromYear = params.get("fromYear");
      let toYear = params.get("toYear");
      let sbQuery = params.get("sbQuery");
      let f1 = params.get("f1");
      let isDateRange = params.get("isDateRange");
      let rangeField = params.get("rangeField");
      let toRange = params.get("toRange");
      let fromRange = params.get("fromRange");
      let filterStr = GenerateArrayStrings(filters);
      filterStr = encodeURIComponent(filterStr);
      let fcount = params.get("fcount");
      let fval1 = params.get("fval1");
      let q = params.get("q");
      let queryType = params.get("queryType");
      let url = ``;
      let isDropdownValueChange = q !== dropdownValue;
      if (
        searchKeyword === undefined ||
        dropdownValue === undefined ||
        filters === undefined
      ) {
        url = `/results?query=&q=&filters=`;
      } else if (
        csPath !== null &&
        csproduct !== null &&
        searchKeyword !== ""
      ) {
        let query = searchKeyword;
        let oldQuery = params.get("query");
        if (
          params.get("fval1") !== null &&
          query.toLowerCase().includes(params.get("fval1").toLowerCase())
        ) {
          let fcount = parseInt(params.get("fcount"));
          let fvalStr = "";
          for (let i = 1; i <= fcount; i++) {
            fvalStr =
              fvalStr +
              `&f${i}=${params.get(`f${i}`)}&fval${i}=${params.get(
                `fval${i}`
              )}`;
          }
          if (sbQuery !== null) {
            let newSbQuery = query.replace(oldQuery, sbQuery);
            url =
              `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}${fvalStr}` +
              `&fcount=${fcount}&sbQuery=${newSbQuery}` +
              `&cspath=${csPath}&csproduct=${csproduct}`;
          } else {
            url =
              `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}${fvalStr}` +
              `&fcount=${fcount}` +
              `&cspath=${csPath}&csproduct=${csproduct}`;
          }
        } else {
          url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        }
      } else if (
        f1 !== null &&
        fval1 !== null &&
        searchKeyword !== "" &&
        csPath === null &&
        csproduct === null &&
        f1 === "tempJNJDrugs"
      ) {
        if (searchKeyword.toLowerCase().includes(fval1.toLowerCase())) {
          if (fieldedVal === "") {
            fieldedVal = f1;
          }
          url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${fval1}&fcount=1&sbQuery=${searchKeyword}`;
        } else {
          url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        }
      }
      //&& (fcount && isDropdownValueChange)
      else if (
        dropdownValue === "All Fields" &&
        !FS &&
        (!fcount || isDropdownValueChange) &&
        !window.isSupportingDocs
      ) {
        url = `/results?query=${searchKeyword}&q=All Fields&filters=${filterStr}`;
      } else if (searchKeyword === "" && !FS && !window.isSupportingDocs) {
        url = `/results?query=&q=All Fields&filters=${filterStr}`;
      } else if (window.isSupportingDocs) {
        url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
        let reFCount = 0;
        if (f1 && fval1) {
          reFCount++;
          url += `&f1=${f1}&fval1=${fval1}`;
        } else {
          window.isSupportingDocs = undefined;
        }
        if (dropdownValue !== "All Fields" && searchKeyword) {
          reFCount++;
          url += `&f${reFCount}=${fieldedVal}&fval${reFCount}=${searchKeyword}`;
        }
        url += `&fcount=${reFCount}&sbQuery=${dropdownValue !== "All Fields" ? "" : searchKeyword
          }`;
      } else {
        if (FS) {
          url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}`;
          let DS = params.get("DS");
          let previousFKey = "";
          let previousFValue = "";
          //if (fieldedVal === "docTypeSynonyms") fieldedVal = "docType";
          if (DS) {
            let DSSplit = DS.split("ǂ");
            if (DSSplit.length === 2) {
              previousFKey = DSSplit[0];
              previousFValue = encodeURIComponent(DSSplit[1]);
            }
          }
          let isKeyAvailable = false;
          let reFcount = 0;
          if (!fcount) fcount = 0;
          for (let i = 1; i <= fcount; i++) {
            let key = params.get(`f${i}`);
            let value = encodeURIComponent(params.get(`fval${i}`));
            if (key === previousFKey) {
              let splitValue = value.split(";");
              splitValue = splitValue.filter((x) => x && x !== previousFValue);
              value = splitValue.join(";");
            }
            // if (key === fieldedVal) {
            //   let splitValue = value.split(";");
            //   splitValue = splitValue.filter(x => x && x !== previousFValue && x !== searchKeyword);
            //   if (searchKeyword) {
            //     splitValue.push(searchKeyword)
            //   }
            //   value = splitValue.join(";");
            //   isKeyAvailable = true;
            // }
            if (value) {
              reFcount++;
              url += `&f${reFcount}=${key}&fval${reFcount}=${value}`;
            }
          }
          if (!isKeyAvailable && searchKeyword && fieldedVal) {
            reFcount++;
            url += `&f${reFcount}=${fieldedVal}&fval${reFcount}=${searchKeyword}`;
          }
          url += `&fcount=${reFcount}`;
          if (searchKeyword && fieldedVal) {
            url += `&DS=${fieldedVal}ǂ${searchKeyword}`;
          }
        } else {
          if (fieldedVal === "") {
            fieldedVal = f1;
          }
          url = `/results?query=${searchKeyword}&q=${dropdownValue}&filters=${filterStr}&f1=${fieldedVal}&fval1=${searchKeyword}&fcount=1&sbQuery=${dropdownValue !== "All Fields" ? "" : searchKeyword
            }`;
        }
      }
      if (FS) {
        if (andFields !== "" && andFields !== null) {
          url += `&andFields=${andFields}`;
        }
        if (orFields !== "" && orFields !== null) {
          url += `&orFields=${orFields}`;
        }
        if (docTitle !== "" && docTitle !== null) {
          url += `&docTitle=${encodeURIComponent(docTitle)}`;
        }
        if (authors !== "" && authors !== null) {
          url += `&authors=${encodeURIComponent(authors)}`;
        }
        if (majorTopicsSynonyms !== "" && majorTopicsSynonyms !== null) {
          url += `&majorTopicsSynonyms=${encodeURIComponent(
            majorTopicsSynonyms
          )}`;
        }
        if (fromYear !== "" && fromYear !== null) {
          url += `&fromYear=${fromYear}`;
        }
        if (toYear !== "" && toYear !== null) {
          url += `&toYear=${toYear}`;
        }
        url += `&FS=true`;
        //if (dropdownValue && dropdownValue !== "All Fields") 
        url += `&sbQuery=`;

      }
      if (isDateRange && rangeField && (fromRange || toRange)) {
        url = url + `&isDateRange=Yes&rangeField=modifiedDate`;
        if (fromRange) {
          url = url + `&fromRange=${fromRange}`;
        }
        if (toRange) {
          url = url + `&toRange=${toRange}`;
        }
      }
      if (queryType) {
        url += `&queryType=alerts`;
      }
      if (isClickOnSuggestion) {
        url += `&isClickOnSuggestion=true`;
      }
      return url;
    }
  };
  handleKeyDown = (event) => {
    //console.log(this.props.appliedFilters)
    // let params = new URLSearchParams(this.props.history.location.search)
    // let csPath = params.get('cspath');
    // let csproduct = params.get('csproduct');

    if (["Enter"].includes(event.key)) {
      this.setState({
        showSuggestions: false,
        isClickOnSuggestion: false,
      });
      let newUrl = this.buildUrl(
        event.target.value,
        this.state.dropdownValue,
        this.state.fieldedValue,
        this.props.appliedFilters
      );
      this.props.handleChangeInSearch(this.state.searchKeyword, newUrl);
      this.props.history.push(newUrl);
      let str = this.createSearchCriteriaString();
      str = str.replace(/(")/g, '\\"');
      const ts = new trackingService();
      ts.indexTrackingData(
        localStorage.getItem("userdisplayName"),
        localStorage.getItem("wwid"),
        localStorage.getItem("username"),
        localStorage.getItem("function"),
        localStorage.getItem("region"),
        localStorage.getItem("userproducts"),
        localStorage.getItem("doctypepref"),
        localStorage.getItem("sid"),
        localStorage.getItem("deviceType"),
        localStorage.getItem("deviceOS"),
        localStorage.getItem("browserName"),
        localStorage.getItem("browserVersion"),
        localStorage.getItem("jnjDepartment"),
        localStorage.getItem("companyname"),
        "Conducted Search",
        "",
        // window.location.href,
        newUrl,
        // this.state.searchKeyword,
        str,
        this.props.appliedFilters,
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        this.props.csPath !== null ? this.props.csPath : "",
        this.props.csproduct !== null ? this.props.csproduct : ""
      );
    }
    if (["ArrowDown"].includes(event.key) || ["ArrowUp"].includes(event.key)) {
      //console.log('Arrow Action')
      this.setState({
        keyBoardEvent: true,
        keyEventName: event.key,
      });
    }
  };
  createSearchCriteriaString() {
    const queryParams = new URLSearchParams(document.location.search);
    const params = queryParams.get("query");
    const filters = queryParams.get("filters");
    const q = queryParams.get("q");
    const fcount = queryParams.get("fcount");
    const majorTopicsSynonyms = queryParams.get("majorTopicsSynonyms");
    const authors = queryParams.get("authors");
    const docTitle = queryParams.get("docTitle");
    let str = "";
    let fieldedSearch = queryParams.get("FS");
    let rlName = queryParams.get("rName");
    let limistArr = [];
    let f1 = "", fval1 = "";

    if (window.location.pathname !== "/ReferencesUsed") {
      if (fcount && fcount > 0) {
        f1 = queryParams.get("f1");
        fval1 = queryParams.get("fval1");
      }
      if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}: ${filters}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}: ${filters}`;
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) && params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}: ${filters}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}: ${filters}`;
        }
      }
      else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${params}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${params}`;
        }
      } else if ((f1 && (f1 === "tempJNJDrugs" || f1 === "taIRO")) &&
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        if (f1 === "tempJNJDrugs") {
          str = `(Products: ${fval1}): ${q}: ${params}`;
        } else {
          str = `(Therapeutic Area: ${fval1}): ${q}: ${params}`;
        }
      }
      else if (
        params !== "" &&
        filters !== "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}: ${filters}`;
      }
      else if (
        params !== "" &&
        filters !== "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}: ${filters}`;
      } else if (
        params !== "" &&
        filters === "" &&
        q === "All Fields" &&
        !fieldedSearch
      ) {
        str = `${params}`;
      } else if (
        params !== "" &&
        filters === "" &&
        q !== "All Fields" &&
        !fieldedSearch
      ) {
        str = `${q}: ${params}`;
      } else if (fieldedSearch) {
        // Publication Year
        let toRange = queryParams.get("toYear");
        let fromRange = queryParams.get("fromYear");
        let PublicationYear = "";
        if (toRange) {
          toRange = parseInt(toRange);
        }
        if (fromRange) {
          fromRange = parseInt(fromRange);
        }
        let strYear = limistArr.find((x) => x.key === "strYear");
        if (strYear) {
          let strYearValue = parseInt(strYear.value);
          if (
            toRange &&
            fromRange &&
            fromRange <= strYearValue &&
            toRange >= strYearValue
          ) {
            PublicationYear = `${fromRange} To ${toRange}`;
          } else if (
            toRange &&
            fromRange &&
            (fromRange > strYearValue || toRange < strYearValue)
          ) {
            PublicationYear = `${fromRange} To ${toRange} AND ${strYearValue}`;
          } else if (toRange && strYearValue) {
            PublicationYear = `${toRange} and earlier AND ${strYearValue}`;
          } else if (fromRange && strYearValue) {
            PublicationYear = `${fromRange} and later AND ${strYearValue}`;
          } else if (strYearValue) {
            PublicationYear = `${strYearValue}`;
          }
        } else if (toRange && fromRange) {
          PublicationYear = `${fromRange} To ${toRange}`;
        } else if (toRange) {
          PublicationYear = `${toRange} and earlier`;
        } else if (fromRange) {
          PublicationYear = `${fromRange} and later`;
        }
        limistArr = limistArr.filter((x) => x.key !== "strYear");
        ///////
        let fcount = queryParams.get("fcount");
        for (let i = 0; i < fcount; i++) {
          let key = queryParams.get(`f${i + 1}`);
          let value = queryParams.get(`fval${i + 1}`);
          limistArr[i] = { key: key, value: value };
        }

        str = params ? `(${params}) ${filters ? ":" + filters : ""}` : "";

        if (!params && params === "" && filters !== "" && filters !== null) {
          str = str + `${filters ? filters : ""}`;
        }

        str = str + (docTitle ? ` (Title: ${docTitle})` : "");
        str = str + (authors ? ` (Authors: ${authors})` : "");
        str =
          str +
          (majorTopicsSynonyms
            ? ` (Major Topics: ${majorTopicsSynonyms})`
            : "");
        str =
          str +
          (PublicationYear ? ` (Publication Year: ${PublicationYear})` : "");
        if (fcount > 0 && limistArr.length > 0) {
          limistArr.map((item, index) => {
            str =
              str +
              (item.key
                ? ` (${this.conversionOfFieldType(item.key)}: ${item.value})`
                : "");
          });
        }
      }

      if (window.supportingDocsSearchStrategyDescription) {
        let searchDes = window.supportingDocsSearchStrategyDescription;
        if (str) {
          str = searchDes + `, ${str}`;
        }
      }
      if (rlName) {
        str = `${rlName}ǂ$±@${str}`;
      }
      return str;
    }
  }
  conversionOfFieldType = (item) => {
    if (item === "docType" || item === "docTypeSynonyms")
      return "Document Type";
    else if (item === "protocolIDs" || item === "harmonizedProtocolIDs") return "Protocol Number";
    else if (item === "genericName") return "Generic Name";
    else if (item === "congressName" || item === "fullCnSf")
      return "Congress Name";
    else if (item === "strYear") return "Publication Year";
    else if (item === "authors") return "Author";
    else if (item === "docTitle") return "Title";
    else return "Journal Name";
  };
  handleOnClickGenAIAnsweringIcon = () => {
    const newUrl = this.buildUrl(
      this.state.searchKeyword,
      this.state.dropdownValue,
      this.state.fieldedValue,
      this.props.appliedFilters
    );
    let str = this.createSearchCriteriaString();
    str = str.replace(/(")/g, '\\"');
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Click GenAIAnswers2.0",
      "",
      newUrl,
      str,
      this.props.appliedFilters,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      ""
    );

  }
  handleOnClickSearchIcon = () => {
    /* let filters = this.props.location.search */
    //console.log(this.state)
    // let params = new URLSearchParams(this.props.history.location.search)
    // let csPath = params.get('cspath');
    // let csproduct = params.get('csproduct');
    if (this.state.searchKeyword === "") {
      this.setState({
        dropdownValue: "All Fields",
      });
    }
    const newUrl = this.buildUrl(
      this.state.searchKeyword,
      this.state.dropdownValue,
      this.state.fieldedValue,
      this.props.appliedFilters
    );
    this.props.handleChangeInSearch(this.state.searchKeyword, newUrl);
    this.props.history.push(newUrl);
    let str = this.createSearchCriteriaString();
    str = str.replace(/(")/g, '\\"');
    const ts = new trackingService();
    ts.indexTrackingData(
      localStorage.getItem("userdisplayName"),
      localStorage.getItem("wwid"),
      localStorage.getItem("username"),
      localStorage.getItem("function"),
      localStorage.getItem("region"),
      localStorage.getItem("userproducts"),
      localStorage.getItem("doctypepref"),
      localStorage.getItem("sid"),
      localStorage.getItem("deviceType"),
      localStorage.getItem("deviceOS"),
      localStorage.getItem("browserName"),
      localStorage.getItem("browserVersion"),
      localStorage.getItem("jnjDepartment"),
      localStorage.getItem("companyname"),
      "Conducted Search",
      "",
      // window.location.href,
      newUrl,
      // this.state.searchKeyword,
      str,
      this.props.appliedFilters,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      this.props.csPath !== null ? this.props.csPath : "",
      this.props.csproduct !== null ? this.props.csproduct : ""
    );
    this.setState({
      keyBoardEvent: false,
      keyEventName: "",
      showSuggestions: false,
    });
  };
  handleOnChange = (query, fieldParam) => {
    let fieldVal, dropdownValue;
    if (fieldParam === "Authors" /* 'all Authors' */) {
      fieldVal = "authors";
      dropdownValue = "Author";
    } else if (fieldParam === "Congresses") {
      fieldVal = "fullCnSf";
      dropdownValue = "Congress";
    } else if (fieldParam === "Titles") {
      fieldVal = "docTitle";
      dropdownValue = "Title";
    } else if (fieldParam === "Document Types") {
      fieldVal = "docTypeSynonyms";
      dropdownValue = "Document Type";
    } else if (fieldParam === "Journals") {
      fieldVal = "journalName";
      dropdownValue = "Journal Name";
    } else if (fieldParam === "Publication Years") {
      fieldVal = "strYear";
      dropdownValue = "Publication Year";
    } else if (fieldParam === null) {
      fieldVal = this.state.fieldedValue;
      dropdownValue = this.state.dropdownValue;
      // console.log(fieldVal);
    } else {
      fieldVal = "";
      dropdownValue = "All Fields";
    }
    this.setState(
      {
        searchKeyword: query,
        showSuggestions: false,
        fieldedValue: fieldVal,
        dropdownValue: dropdownValue,
      },
      () => {
        this.handleOnClickSearchIcon();
      }
    );
  };
  handleCategoryClose = () => {
    this.setState({
      showOptions: false,
    });
  };
  handleCloseSuggestions = (event) => {
    this.setState({
      showSuggestions: false,
    });
    /* if(this.inputBoxRef && !this.inputBoxRef.current.contains(event.target)){
            this.setState({
                showSuggestions : false
            })
        } */
  };
  // componentDidUpdate() {
  //   console.log('Component Updated', this.props.customWidth);
  //   // if(this.state.searchKeyword.length>3){
  //   //     throw new Error('I Crashed');
  //   // }
  // }
  handelViewSelected = () => {
    // // if (this.props.isCitationSelected.includes(true)) {
    //   if (this.props.isCitationSelected.length > 0) {
    //   // console.log(this.props.isCitationSelected)
    // }
    // else {
    // }
    this.props.handelSelectedCitation();
  };
  handleCollapseAllGlobal = () => {
    this.props.handleCollapseAllGlobal();
  };
  handleSelectAll = () => {
    this.props.handleSelectAll();
  };
  render() {
    let { bannerMessage } = this.state;
    let _function = JSON.parse(localStorage.getItem('function'));
    if (this.props.customWidth < 600) {
      return (
        <div className="header-wrapper-mobileView">
          {(bannerMessage && (window.location.pathname === "/recommended" || window.location.pathname === "/")) && <p className="bannerMessage-tablet">{bannerMessage}</p>}
          <div
            className="searchInput-container"
            onMouseLeave={this.handleCloseSuggestions}
          >
            <input
              className="input-search-box-mobileView"
              type="text"
              placeholder="What are you looking for?"
              title="What are you looking for?"
              value={this.state.searchKeyword}
              onChange={this.handleChangeInSearch}
              onKeyDown={this.handleKeyDown}
              autoComplete="off"
            />
            <span className="icon-wrapper">
              {this.state.searchKeyword && (
                <img
                  src={crossIcon}
                  className="input-search-cancel-icon"
                  alt="clear"
                  title="Click here to clear the search terms"
                  onClick={this.handleCancelSearchKeyword}
                />
              )}
              <span
                className="search-icon-wrapper-mobileView"
                onClick={(e) => {
                  this.handleOnClickSearchIcon();
                  this.handleOnSuggestionClick(false);
                }}
              >
                <img
                  src={magnifyingGlass}
                  className="magnifyingGlass-mobileView"
                  alt="search"
                  title="Search"
                // onClick={this.handleOnClickSearchIcon}
                />
              </span>
            </span>
            {this.state.searchKeyword &&
              this.state.searchKeyword.length >= 2 && (
                <SuggestionsList
                  searchKeyword={this.state.searchKeyword}
                  handleOnChange={this.handleOnChange}
                  keyBoardEvent={this.state.keyBoardEvent}
                  keyEventName={this.state.keyEventName}
                  showSuggestions={this.state.showSuggestions}
                  handleOnSuggestionClick={this.handleOnSuggestionClick}
                />
              )}
          </div>
          <hr className="header-sepration-line" />
        </div>
      );
    } else if (this.props.customWidth < 1000) {
      return (
        <div className="headerWithBannerWrapper">
          <div
            className="bannerImage"
            style={{ padding: "0 0 0%", margin: "0 0 1%" }}
          >
            {(bannerMessage && (window.location.pathname === "/recommended" || window.location.pathname === "/")) && <p className="bannerMessage-tablet">{bannerMessage}</p>}
            <Link to="/">
              <span>
                <img
                  src={IMSLogo}
                  alt=""
                  style={{ paddingTop: "1.5%", marginBottom: "0.5%" }}
                />
                {/* <img src={iMSLogoTabletView} alt="" style={{ paddingTop: "2%" }} /> */}
              </span>
            </Link>
            <div className="search-input-wrapper-tabletView">
              <div
                onMouseLeave={this.handleCloseSuggestions}
                className="search-input-innerWrapper"
              >
                <div
                  className="dropdown searchSelect"
                  onMouseLeave={this.handleCategoryClose}
                >
                  <button className="btn" onClick={this.handleCategoryDropdown}>
                    <span>{this.state.dropdownValue}</span>
                    <span className="img-wrapper-tabletView">
                      <img
                        src={dropdownArrowBlack}
                        alt="dropdownIcon"
                        style={{
                          transform: this.state.showOptions
                            ? "matrix(-1, 0, 0, -1, 0, 0)"
                            : null,
                          margin: this.state.showOptions
                            ? "0px 0px 10px 0px"
                            : "7px 0px 0px 0px",
                        }}
                      />
                    </span>
                  </button>
                  {this.state.showOptions ? (
                    <ul className="allFieldsListing">
                      {this.state.dropdownValue === "All Fields" ? null : (
                        <li
                          keyvalue="All Fields"
                          onClick={this.handleChangeInDropdown}
                        >
                          All Fields
                        </li>
                      )}
                      {this.state.dropdownValue === "Author" ? null : (
                        <li
                          keyvalue="Author"
                          onClick={this.handleChangeInDropdown}
                        >
                          Author
                        </li>
                      )}
                      {this.state.dropdownValue === "Congress" ? null : (
                        <li
                          keyvalue="Congress"
                          onClick={this.handleChangeInDropdown}
                        >
                          Congress
                        </li>
                      )}
                      {this.state.dropdownValue === "Document Type" ? null : (
                        <li
                          keyvalue="Document Type"
                          onClick={this.handleChangeInDropdown}
                        >
                          Document Type
                        </li>
                      )}
                      {this.state.dropdownValue === "Title" ? null : (
                        <li
                          keyvalue="Title"
                          onClick={this.handleChangeInDropdown}
                        >
                          Title
                        </li>
                      )}
                      {this.state.dropdownValue === "Publication Year" ? null : (
                        <li
                          keyvalue="Publication Year"
                          onClick={this.handleChangeInDropdown}
                        >
                          Publication Year
                        </li>
                      )}
                      {this.state.dropdownValue === "Journal Name" ? null : (
                        <li
                          keyvalue="Journal Name"
                          onClick={this.handleChangeInDropdown}
                        >
                          Journal Name
                        </li>
                      )}
                    </ul>
                  ) : null}
                </div>
                <div className="input-box-wrapper-tabletView">
                  <input
                    name="searchBar"
                    placeholder="What are you looking for?"
                    className="input-search-box-tabletView"
                    title="What are you looking for?"
                    value={this.state.searchKeyword}
                    onChange={this.handleChangeInSearch}
                    onKeyDown={this.handleKeyDown}
                    autoComplete="off"
                  />
                  {/* <span
                    className="icon-wrapper" style={{
                      width: this.state.searchKeyword.length > 0 ?  "112px" "110px" : "67px"
                    }}
                  > */}
                  {/* {this.state.searchKeyword && (
                      <>
                        <img
                          src={crossIcon}
                          className="input-search-cancel-icon"
                          alt="clear"
                          title="Click here to clear the search terms"
                          onClick={this.handleCancelSearchKeyword}
                        />
                        <div class="vline"></div>
                      </>
                    )} */}
                  {/* <img
                    src={searchIcon}
                    className="search-icon-wrapper"
                    alt="search"
                    title="Search"
                    onClick={this.handleOnClickSearchIcon}
                  /> */}
                  {/* <button title="Search" className="search-button-wrapper" onClick={this.handleOnClickSearchIcon}>
                      Search
                    </button> */}

                  {/* </span> */}
                  <span className="icon-wrapper">
                    {/* {this.state.searchKeyword && ( */}
                    {/* <> */}
                    {this.state.searchKeyword && (
                      <img
                        src={crossIcon}
                        className="input-search-cancel-icon"
                        alt="clear"
                        title="Click here to clear the search terms"
                        onClick={this.handleCancelSearchKeyword}
                      />
                    )}
                    <span
                      className="search-icon-wrapper-mobileView"
                      onClick={(e) => {
                        this.handleOnClickSearchIcon();
                        this.handleOnSuggestionClick(false);
                      }}
                    >
                      <img
                        src={magnifyingGlass}
                        className="magnifyingGlass-mobileView"
                        alt="search"
                        title="Search"
                      // onClick={this.handleOnClickSearchIcon}
                      />
                    </span>
                    {/* </>
            )} */}
                  </span>
                  {this.state.searchKeyword &&
                    this.state.searchKeyword.length >= 2 && (
                      <SuggestionsList
                        searchKeyword={this.state.searchKeyword}
                        handleOnChange={this.handleOnChange}
                        keyBoardEvent={this.state.keyBoardEvent}
                        keyEventName={this.state.keyEventName}
                        showSuggestions={this.state.showSuggestions}
                        handleOnSuggestionClick={this.handleOnSuggestionClick}
                      />
                    )}
                </div>
                <div className="fielded-search-icon-wrapper-tabletView">
                  <span className="fielded-search-icon-inner-wrapper-tabletView">
                    <Link to="/advancedsearch">
                      <img
                        src={fieldedSrchIcon}
                        width="45"
                        height="45"
                        title="Fielded Search"
                        alt="Fielded Search"
                      />
                    </Link>
                  </span>
                </div>
              </div>
            </div>
            <div className="search-input-below-wrapper-without-item"></div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="headerWithBannerWrapper">
          <div
            className={
              window.location.pathname === "/" ||
                window.location.pathname === "/recommended"
                ? "bannerImage bannerImage-recommended"
                : "bannerImage"
            }
            style={{ padding: "0 0 0%", margin: "0 0 1%" }}>
            {(bannerMessage && (window.location.pathname === "/recommended" || window.location.pathname === "/")) && <p className="bannerMessage">{bannerMessage}</p>}
            <Link to="/">
              <span>
                <img
                  src={IMSLogo}
                  alt=""
                  style={{ paddingTop: "1.5%", marginBottom: "0.5%" }}
                />
              </span>
            </Link>
            {/*(_function !== "Medical" || true) ?*/}
            {(_function !== "Medical") ?             
              <div className="search-input-wrapper">
                <div onMouseLeave={this.handleCloseSuggestions}>
                  <div
                    className="dropdown searchSelect"
                    onMouseLeave={this.handleCategoryClose}
                  >
                    <button className="btn" onClick={this.handleCategoryDropdown}>
                      <span>{this.state.dropdownValue}</span>
                      <span>
                        <img
                          src={dropdownArrowBlack}
                          alt="dropdownIcon"
                          style={{
                            transform: this.state.showOptions
                              ? "matrix(-1, 0, 0, -1, 0, 0)"
                              : null,
                            margin: this.state.showOptions
                              ? "0px 0px 14px 0px"
                              : "5px 0px 0px 0px",
                          }}
                        />
                      </span>
                    </button>
                    {this.state.showOptions ? (
                      <ul className="allFieldsListing">
                        {this.state.dropdownValue === "All Fields" ? null : (
                          <li
                            keyvalue="All Fields"
                            onClick={this.handleChangeInDropdown}
                          >
                            All Fields
                          </li>
                        )}
                        {this.state.dropdownValue === "Author" ? null : (
                          <li
                            keyvalue="Author"
                            onClick={this.handleChangeInDropdown}
                          >
                            Author
                          </li>
                        )}
                        {this.state.dropdownValue === "Congress" ? null : (
                          <li
                            keyvalue="Congress"
                            onClick={this.handleChangeInDropdown}
                          >
                            Congress
                          </li>
                        )}
                        {this.state.dropdownValue === "Document Type" ? null : (
                          <li
                            keyvalue="Document Type"
                            onClick={this.handleChangeInDropdown}
                          >
                            Document Type
                          </li>
                        )}
                        {this.state.dropdownValue === "Title" ? null : (
                          <li
                            keyvalue="Title"
                            onClick={this.handleChangeInDropdown}
                          >
                            Title
                          </li>
                        )}
                        {this.state.dropdownValue === "Publication Year" ? null : (
                          <li
                            keyvalue="Publication Year"
                            onClick={this.handleChangeInDropdown}
                          >
                            Publication Year
                          </li>
                        )}
                        {this.state.dropdownValue === "Journal Name" ? null : (
                          <li
                            keyvalue="Journal Name"
                            onClick={this.handleChangeInDropdown}
                          >
                            Journal Name
                          </li>
                        )}
                      </ul>
                    ) : null}
                  </div>
                  <div className="input-box-wrapper">
                    <input
                      name="searchBar"
                      placeholder="What are you looking for?"
                      className="input-search-box"
                      title="What are you looking for?"
                      value={this.state.searchKeyword}
                      onChange={this.handleChangeInSearch}
                      onKeyDown={this.handleKeyDown}
                      autoComplete="off"
                    />
                    <span className="icon-wrapper">
                      {this.state.searchKeyword && (
                        <img
                          src={crossIcon}
                          className="input-search-cancel-icon"
                          alt="clear"
                          title="Click here to clear the search terms"
                          onClick={this.handleCancelSearchKeyword}
                        />
                      )}
                      <span
                        className="search-icon-wrapper-webView"
                        onClick={(e) => {
                          this.handleOnClickSearchIcon();
                          this.handleOnSuggestionClick(false);
                        }}
                      >
                        <img
                          src={magnifyingGlass}
                          className="magnifyingGlass-mobileView"
                          alt="search"
                          title="Search"
                        />
                      </span>
                    </span>
                    {/* <span
                  className="icon-wrapper" style={{
                    width: this.state.searchKeyword.length > 0 ? "112px" : "67px"
                  }}
                >
                  {this.state.searchKeyword && (
                    <>
                      <img
                        src={crossIcon}
                        className="input-search-cancel-icon"
                        alt="clear"
                        title="Click here to clear the search terms"
                        onClick={this.handleCancelSearchKeyword}
                      />
                      <div class="vline"></div>
                    </>
                  )} */}
                    {/* <img
                  src={searchIcon}
                  className="search-icon-wrapper"
                  alt="search"
                  title="Search"
                  onClick={this.handleOnClickSearchIcon}
                /> */}
                    {/* <button title="Search" className="search-button-wrapper" onClick={this.handleOnClickSearchIcon}>
                    Search
                  </button> */}
                    {/* </span> */}
                    {this.state.searchKeyword &&
                      this.state.searchKeyword.length >= 2 && (
                        <SuggestionsList
                          searchKeyword={this.state.searchKeyword}
                          handleOnChange={this.handleOnChange}
                          keyBoardEvent={this.state.keyBoardEvent}
                          keyEventName={this.state.keyEventName}
                          showSuggestions={this.state.showSuggestions}
                          handleOnSuggestionClick={this.handleOnSuggestionClick}
                        />
                      )}
                  </div>
                  <div className="fielded-search-icon-wrapper">
                    <span className="fielded-search-icon-inner-wrapper">
                      <Link to="/advancedsearch">
                        <img
                          src={fieldedSrchIcon}
                          width="45"
                          height="45"
                          title="Fielded Search"
                        />
                      </Link>
                    </span>
                  </div>
                  {/* {this.props.customWidth > 1330 && ( */}
                  <>
                    {window.location.pathname === "/results" ||
                      window.location.pathname === "/viewReadingList" ? (
                      <div className="clear-search-button-wrapper">
                        <Button
                          text="Clear Search"
                          customColor="#E39F00"
                          action={() => {
                            this.props.handleClearSearch();
                            this.setState({
                              dropdownValue: "All Fields",
                              searchKeyword: "",
                              appliedFilters: [],
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div
                        className="clear-search-button-wrapper"
                        style={{ position: "absolute" }}
                      >
                        <div
                          className="search-icon-wrapper"
                          style={{ minWidth: "130px" }}
                          onMouseEnter={this.handleOnInfoHover}
                          onMouseLeave={this.handleOnInfoHoverLeave}
                        >
                          <img src={iIcon} alt="" width="17" height="17" />
                          <span className="search-tip-text">Search Tips</span>
                        </div>
                        {this.state.infoPopUp && (
                          <div className="search-text-info-wrapper">
                            <div className="search-help-text-tooltip"></div>
                            <span className="tooltiptext tooltip-right">
                              <ul>
                                <li>
                                  If you use <b>Boolean Operators</b>, you must
                                  enter them in <b>ALL CAPS</b> (i.e., AND, OR,
                                  NOT, NEAR)
                                </li>
                                <li>
                                  When searching <b>Author names</b>, enter only
                                  the last name (e.g., 'Ryan' for 'Charles Ryan')
                                </li>
                                {/* <li>
                              To search exact phrase, enter your terms in
                              double quotes (e.g., "multiple myeloma")
                            </li>
                            <li>
                              Use the asterisk (*) as a wild card for
                              searching word variants (e.g., inhibit* will
                              search the words inhibit, inhibits, inhibited,
                              inhibiting, etc.)
                            </li> */}
                              </ul>
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </>
                  {/* )} */}
                </div>
              </div>
              :
              <div className="search-input-wrapper search-input-gen-ai-button-wrapper">
                <div className="bannerImage-left">
                  <span className="answering-banner-message">TRY OUR NEW GENAI ASSISTANT!</span>
                  <Link to="/genAIAnswering" target="_blank" onClick={(e) => {
                    this.handleOnClickGenAIAnsweringIcon();

                  }}>
                    <span>
                      <img
                        src={GenAIAnswers}
                        alt=""
                        className="gen-ai-answer-btn"
                      />
                    </span>
                  </Link>
                </div>
                <div className="bannerImage-right">
                  <div onMouseLeave={this.handleCloseSuggestions}>
                    <div
                      className="dropdown searchSelect"
                      onMouseLeave={this.handleCategoryClose}
                    >
                      <button className="btn" onClick={this.handleCategoryDropdown}>
                        <span>{this.state.dropdownValue}</span>
                        <span>
                          <img
                            src={dropdownArrowBlack}
                            alt="dropdownIcon"
                            style={{
                              transform: this.state.showOptions
                                ? "matrix(-1, 0, 0, -1, 0, 0)"
                                : null,
                              margin: this.state.showOptions
                                ? "0px 0px 14px 0px"
                                : "5px 0px 0px 0px",
                            }}
                          />
                        </span>
                      </button>
                      {this.state.showOptions ? (
                        <ul className="allFieldsListing">
                          {this.state.dropdownValue === "All Fields" ? null : (
                            <li
                              keyvalue="All Fields"
                              onClick={this.handleChangeInDropdown}
                            >
                              All Fields
                            </li>
                          )}
                          {this.state.dropdownValue === "Author" ? null : (
                            <li
                              keyvalue="Author"
                              onClick={this.handleChangeInDropdown}
                            >
                              Author
                            </li>
                          )}
                          {this.state.dropdownValue === "Congress" ? null : (
                            <li
                              keyvalue="Congress"
                              onClick={this.handleChangeInDropdown}
                            >
                              Congress
                            </li>
                          )}
                          {this.state.dropdownValue === "Document Type" ? null : (
                            <li
                              keyvalue="Document Type"
                              onClick={this.handleChangeInDropdown}
                            >
                              Document Type
                            </li>
                          )}
                          {this.state.dropdownValue === "Title" ? null : (
                            <li
                              keyvalue="Title"
                              onClick={this.handleChangeInDropdown}
                            >
                              Title
                            </li>
                          )}
                          {this.state.dropdownValue === "Publication Year" ? null : (
                            <li
                              keyvalue="Publication Year"
                              onClick={this.handleChangeInDropdown}
                            >
                              Publication Year
                            </li>
                          )}
                          {this.state.dropdownValue === "Journal Name" ? null : (
                            <li
                              keyvalue="Journal Name"
                              onClick={this.handleChangeInDropdown}
                            >
                              Journal Name
                            </li>
                          )}
                        </ul>
                      ) : null}
                    </div>
                    <div className="input-box-wrapper">
                      <input
                        name="searchBar"
                        placeholder="What are you looking for?"
                        className="input-search-box"
                        title="What are you looking for?"
                        value={this.state.searchKeyword}
                        onChange={this.handleChangeInSearch}
                        onKeyDown={this.handleKeyDown}
                        autoComplete="off"
                      />
                      <span className="icon-wrapper">
                        {this.state.searchKeyword && (
                          <img
                            src={crossIcon}
                            className="input-search-cancel-icon"
                            alt="clear"
                            title="Click here to clear the search terms"
                            onClick={this.handleCancelSearchKeyword}
                          />
                        )}
                        <span
                          className="search-icon-wrapper-webView"
                          onClick={(e) => {
                            this.handleOnClickSearchIcon();
                            this.handleOnSuggestionClick(false);
                          }}
                        >
                          <img
                            src={magnifyingGlass}
                            className="magnifyingGlass-mobileView"
                            alt="search"
                            title="Search"
                          />
                        </span>
                      </span>
                      {/* <span
                  className="icon-wrapper" style={{
                    width: this.state.searchKeyword.length > 0 ? "112px" : "67px"
                  }}
                >
                  {this.state.searchKeyword && (
                    <>
                      <img
                        src={crossIcon}
                        className="input-search-cancel-icon"
                        alt="clear"
                        title="Click here to clear the search terms"
                        onClick={this.handleCancelSearchKeyword}
                      />
                      <div class="vline"></div>
                    </>
                  )} */}
                      {/* <img
                  src={searchIcon}
                  className="search-icon-wrapper"
                  alt="search"
                  title="Search"
                  onClick={this.handleOnClickSearchIcon}
                /> */}
                      {/* <button title="Search" className="search-button-wrapper" onClick={this.handleOnClickSearchIcon}>
                    Search
                  </button> */}
                      {/* </span> */}
                      {this.state.searchKeyword &&
                        this.state.searchKeyword.length >= 2 && (
                          <SuggestionsList
                            searchKeyword={this.state.searchKeyword}
                            handleOnChange={this.handleOnChange}
                            keyBoardEvent={this.state.keyBoardEvent}
                            keyEventName={this.state.keyEventName}
                            showSuggestions={this.state.showSuggestions}
                            handleOnSuggestionClick={this.handleOnSuggestionClick}
                          />
                        )}
                    </div>
                    <div className="fielded-search-icon-wrapper">
                      <span className="fielded-search-icon-inner-wrapper">
                        <Link to="/advancedsearch">
                          <img
                            src={fieldedSrchIcon}
                            width="45"
                            height="45"
                            title="Fielded Search"
                          />
                        </Link>
                      </span>
                    </div>
                    {/* {this.props.customWidth > 1330 && ( */}
                    <>
                      {window.location.pathname === "/results" ||
                        window.location.pathname === "/viewReadingList" ? (
                        <div className="clear-search-button-wrapper">
                          <Button
                            text="Clear Search"
                            customColor="#E39F00"
                            action={() => {
                              this.props.handleClearSearch();
                              this.setState({
                                dropdownValue: "All Fields",
                                searchKeyword: "",
                                appliedFilters: [],
                              });
                            }}
                          />
                        </div>
                      ) : (
                        <div
                          className="clear-search-button-wrapper"
                          style={{ position: "absolute" }}
                        >
                          <div
                            className="search-icon-wrapper"
                            style={{ minWidth: "130px" }}
                            onMouseEnter={this.handleOnInfoHover}
                            onMouseLeave={this.handleOnInfoHoverLeave}
                          >
                            <img src={iIcon} alt="" width="17" height="17" />
                            <span className="search-tip-text">Search Tips</span>
                          </div>
                          {this.state.infoPopUp && (
                            <div className="search-text-info-wrapper">
                              <div className="search-help-text-tooltip"></div>
                              <span className="tooltiptext tooltip-right">
                                <ul>
                                  <li>
                                    If you use <b>Boolean Operators</b>, you must
                                    enter them in <b>ALL CAPS</b> (i.e., AND, OR,
                                    NOT, NEAR)
                                  </li>
                                  <li>
                                    When searching <b>Author names</b>, enter only
                                    the last name (e.g., 'Ryan' for 'Charles Ryan')
                                  </li>
                                  {/* <li>
                              To search exact phrase, enter your terms in
                              double quotes (e.g., "multiple myeloma")
                            </li>
                            <li>
                              Use the asterisk (*) as a wild card for
                              searching word variants (e.g., inhibit* will
                              search the words inhibit, inhibits, inhibited,
                              inhibiting, etc.)
                            </li> */}
                                </ul>
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </>
                  </div>
                </div>
              </div>
            }
            {window.location.pathname === "/results" ||
              window.location.pathname === "/viewReadingList" ? (
              <div className="globalSearch-wrapper">
                <GlobalFeatures
                  customWidth={this.props.customWidth}
                  customHeight={this.props.customHeight}
                  handelViewSelected={this.handelViewSelected}
                  handleCollapseAllGlobal={this.handleCollapseAllGlobal}
                  globalCollapseAll={this.props.globalCollapseAll}
                  handleSelectAll={this.handleSelectAll}
                  globalSelectAll={this.props.globalSelectAll}
                  selectedResults={this.props.selectedResults}
                  querytext={this.state.searchKeyword}
                  appliedFilters={this.props.appliedFilters}
                  documentsUrl={this.props.documentsUrl}
                  markedDocumentsUrl={this.props.markedDocumentsUrl}
                  itemsPerPage={this.props.itemsPerPage}
                />
              </div>
            ) : (
              <>
                {/* {this.props.customWidth > 1220 ? ( */}
                {/* <div className="search-input-below-wrapper">
                  <div className="search-header-links-component">
                    {this.props.customWidth >= 1464 && (
                      <div className="coronavirus-pink-icon">
                        <img src={coronavirusPinkIcon} alt="coronavirus-icon" />
                      </div>
                    )}
                    <div className="search-header-links">
                      <Link
                        target="_blank"
                        to="/results?query=&q=All Fields&filters=Document Type/Scientific Response Documents;Drugs - JNJ/Antiinfective Drugs and Vaccines/Vaccines/Antiviral Vaccines COVID-19/Ad26.COV2.S&f1=tempJNJDrugs&fval1=Ad26.COV2.S&fcount=1"
                      >
                        Janssen COVID-19 Vaccine Scientific Responses
                      </Link>
                    </div>
                  </div>
                  <div className="search-header-links-component">
                    {this.props.customWidth >= 1464 && (
                      <div className="coronavirus-blue-icon">
                        <img src={coronavirusBlueIcon} alt="coronavirus-icon" />
                      </div>
                    )}
                    <div className="search-header-links">
                      <Link
                        target="_blank"
                        to="/results?query=&q=All Fields&filters=Document Type/Scientific Response Documents;Disease States/Infectious Diseases/Coronavirus"
                      >
                        All COVID-19 Scientific Responses
                      </Link>
                    </div>
                  </div>
                  <div className="search-header-links-component">
                    {this.props.customWidth >= 1464 && (
                      <div className="coronavirus-purple-icon">
                        <img
                          src={coronavirusPurpleIcon}
                          alt="coronavirus-icon"
                        />
                      </div>
                    )}
                    <div className="search-header-links">
                      <Link
                        target="_blank"
                        to="/results?query=&q=All Fields&filters=Drugs - JNJ/Antiinfective Drugs and Vaccines/Vaccines/Antiviral Vaccines COVID-19/Ad26.COV2.S;Document Type/Labeling Documents - Core, EU, US"
                      >
                        Janssen COVID-19 Vaccine Labels
                      </Link>
                    </div>
                  </div>
                  <div className="search-header-links-component">
                    {this.props.customWidth >= 1464 && (
                      <div className="coronavirus-yellow-icon">
                        <img
                          src={coronavirusYellowIcon}
                          alt="coronavirus-icon"
                        />
                      </div>
                    )}
                    <div className="search-header-links">
                      <a
                        href="javascript:void(0)"
                        onClick={(e) => this.handleOpenContentTitle()}
                      >
                        COVID-19 Content by Product
                      </a>
                    </div>
                  </div>
                </div> */}
                {/* ) : (
                  <div className="search-input-below-wrapper-without-item"></div>
                )} */}
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
export default HeaderSearch;
